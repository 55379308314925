<template>
  <div class="container">
    <div class="row" v-if="form">
      <div class="col">
        <div class="card border-0 bg-light">
          <div class="card-body">
            <h5>Form Preview: {{ form.title }}</h5>
            <div class="row">
              <div class="col-12">
                <div class="row mb-3">
                  <div class="col">
                    <div class="accordion" id="sectionsAccordion">
                      <div v-for="section in form.sections" class="shadow">
                        <div class="card shadow-none w-100">
                          <div
                            class="card-header ps-1 pe-3"
                            :id="'heading' + section.id"
                          >
                            <h2 class="mb-0">
                              <button
                                class="
                                  btn btn-link
                                  fw-bold
                                  w-75
                                  text-start
                                "
                                type="button"
                                data-toggle="collapse"
                                :data-target="'#collapse' + section.id"
                                aria-expanded="false"
                                :aria-controls="'collapse' + section.id"
                              >
                                <i class="far fa-caret-down me-2"></i>
                                {{ section.title }}
                              </button>
                            </h2>
                          </div>
                        </div>
                        <div class="card">
                          <div
                            :id="'collapse' + section.id"
                            class="collapse"
                            :aria-labelledby="'heading' + section.id"
                            data-parent="#sectionsAccordion"
                          >
                            <div class="card-body" :ref="'body-' + section.id">
                              <form
                                :id="'form-render-' + section.id"
                                :class="'form-render-' + section.id"
                              ></form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
require("jquery-ui-bundle");
require("formBuilder/dist/form-render.min.js");
export default {
  data() {
    return {
      form: null,
    };
  },
  methods: {
    fetchForm() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/forms/api/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.form = data;

          for (let i = 0; i < this.form.sections.length; i++) {
            const section = this.form.sections[i];

            setTimeout(() => {
              var formRenderOptions = {
                dataType: "json",
                formData: section.form_options,
              };

              $(".form-render-" + section.id).formRender(formRenderOptions);
            }, 500);
          }
        });
    },
  },
  mounted() {
    this.fetchForm();
  },
};
</script>

<style>
.formbuilder-radio-inline > label {
  display: inline !important;
  margin-left: 5px;
  margin-right: 10px;
}
</style>
